import { combineReducers, configureStore } from '@reduxjs/toolkit';

// reducers
import { reducer as locations } from './locations/reducers';

const reducer = combineReducers({
  locations,
});

/**
 * This store is used in public facing apps that don't require user authentication.
 */
export const storeAnonymous = configureStore({
  reducer,
});
